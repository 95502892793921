import "./volos-dashboard.scss";
import * as template from "./volos-dashboard.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { Dashboard } from "../dashboard/dashboard";
import { DataStorageTile } from "../data-storage-tile/data-storage-tile";
import { ItemInspectorTile } from "../item-inspector-tile/item-inspector-tile";
import { VolosDashboardOptions } from "./types";
import { EnergyConsumptionAreaTile } from "../energy-consumption-area-tile/energy-consumption-area-tile";

export class VolosDashboard extends Dashboard<VolosDashboardOptions> {

    constructor(context: InvipoContext, options?: VolosDashboardOptions) {
        super(context, template, options);
    }

    protected createColumns(): void {
        // Create columns
        this.columns = [
            {
                name: "System",
                width: "1",
                tiles: [
                    new DataStorageTile(this.context),
                    new ItemInspectorTile(this.context)
                ]
            },
            {
                name: "Energy",
                width: "2",
                tiles: []
            }
        ];

        // Get list of traffic volume areas
        let areas = this.context.data.getAreas({
            type: "SmartBuilding"
        });

        // Create energy tiles dynamically
        for (let area of areas) {
            // Add tile
            this.columns[1].tiles.push(new EnergyConsumptionAreaTile(this.context, {
                areaId: area.id
            }));
        }
    }

}
