import "./energy-meter-overview.scss";
import * as template from "./energy-meter-overview.hbs";
import { InvipoContext } from "invipo/context/invipo-context";
import { DetailHistoryValue } from "../../common/detail-panel/types";
import { EnergyMeterOverviewOptions } from "./types";
import { Panel } from "../../common/panel/panel";
import { PanelChart, PanelKpis, PanelTable, PanelTableRow } from "../../common/panel/types";
import { Helpers } from "../../../../hiyo/helpers";
import { METRICS } from "../../city/city-subdomain/types";

export class EnergyMeterOverview extends Panel<EnergyMeterOverviewOptions> {

    // Properties
    public kpis: PanelKpis;
    public chart: PanelChart;
    public table: PanelTable;

    constructor(context: InvipoContext, options?: EnergyMeterOverviewOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Build speed KPIs
        this.kpis = {
            label: "components.EnergyMeterOverview.energyStatus",
            size: "Half",
            data: [
                {
                    label: "components.EnergyMeterOverview.currentConsumption",
                    value: `${Helpers.toNumber(this.item.data?.consumption.consumption, 1, 1)} kWh`,
                    description: Helpers.toShortDateTimeString(this.item.data?.consumption?.ts)
                },
                {
                    label: "components.EnergyMeterOverview.currentReading",
                    value: `${Helpers.toNumber(this.item.data?.energy.reading, 1, 1)} kWh`,
                    description: Helpers.toShortDateTimeString(this.item.data?.energy?.ts)
                }
            ]
        }

        // Build count table
        this.table = {
            name: "Table",
            label: "components.EnergyMeterOverview.heatmap",
            columns: [
                {
                    style: "Label",
                    label: "tables.columns.date",
                    width: "99%"
                },
            ],
            rows: []
        };

        // Add hours as columns
        for (let i = 0; i < 24; i++) {
            this.table.columns.push(
                {
                    style: "Small",
                    label: String(i).padStart(2, "0"),
                    align: "Center",
                    width: "30px"
                }
            )
        }

        // Interval
        let from = new Date(new Date().setHours(-24 * 6, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0) - 1);

        // Consumption data
        let hours = await this.context.invipo.getQuery("consumption-by-hour", `item.id=${this.options.itemId}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Iterate by hour
        while (from.getTime() < to.getTime()) {
            // Single row
            let row: PanelTableRow = {
                cells: [
                    `${Helpers.toShortWeekDayString(from)} ${Helpers.toDateString(from)}`
                ]
            }

            // Add hour columns
            for (let i = 0; i < 24; i++) {
                // Find data by timestamp
                let data = hours.find(x => x.timestamp == from.toISOString());
                let color = "";

                // Get color from range
                if (data) {
                    let range = Helpers.range(0, METRICS.energy.metering.consumption.colors.length - 1, METRICS.energy.metering.consumption.range[0], METRICS.energy.metering.consumption.range[1] / 10, data.consumption);
                    color = METRICS.energy.metering.consumption.colors[Math.round(range)];
                }

                // Push consumption to cell
                row.cells.push({
                    label: data ? Helpers.toNumber(data.consumption, 1) : " ",
                    color: `${color}88`
                });

                // Add one more hour
                from.setTime(from.getTime() + 3600000)
            }

            // Add row to table
            this.table.rows.push(row);
        }
    }
}
