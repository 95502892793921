import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, OCCUPANCY_CIRCLE_AREA, OCCUPANCY_STROKE_AREA, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";
import { Helpers } from "../../../hiyo/helpers";
import { METRICS } from "../../components/city/city-subdomain/types";
import { InvipoItem } from "../../clients/invipo-client/types";

export class HotspotConnectionsCircleLayer extends MapLayer<InvipoContext> {

    public constructor(context: InvipoContext) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            card: "WifiAccessPointCard",
            detail: "CityDetail",
            layer: {
                id: "hotspot-connection-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, ["*", ["get", "scale"], 1],
                        12, ["*", ["get", "scale"], 4],
                        16, ["*", ["get", "scale"], 8],
                        19, ["*", ["get", "scale"], 10],
                        22, ["*", ["get", "scale"], 12]
                    ],
                    "circle-stroke-color": ["get", "color"],
                    "circle-stroke-opacity": 0, // 0 -> 1
                    "circle-stroke-opacity-transition": {
                        duration: TRANSITION_DURATION
                    },
                    "circle-stroke-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, 3,
                        16, 6
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-stroke-opacity": OCCUPANCY_STROKE_AREA,
                "circle-opacity": OCCUPANCY_CIRCLE_AREA,
            }
        });
    }

    public async load(): Promise<any> {
        // Load items
        let items = this.context.data.getItems({
            class: "WifiAccessPoint"
        });

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Sort by level
        items.sort((a: InvipoItem, b: InvipoItem) => {
            return (b.data?.network?.connections ?? 0) - (a.data?.network?.connections ?? 0);
        });

        // Push features from items
        for (let item of items) {
            // Default color
            let color = "#aaaaaa";

            // No data?
            if (item.data?.network?.connections != null) {
                // Color calculation from range
                let range = Helpers.range(0, METRICS.services.hotspots.connections.colors.length - 1, METRICS.services.hotspots.connections.range[0], METRICS.services.hotspots.connections.range[1], item.data.network.connections);
                color = METRICS.services.hotspots.connections.colors[Math.round(range)];
            }

            json.features.push({
                type: "Feature",
                properties: {
                    data: "connections",
                    tooltip: item.name,
                    card: this.options.card,
                    detail: this.options.detail,
                    cardId: item.id,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    color: color,
                    scale: 4
                },
                geometry: item.geometry["location"]
            });
        }

        return json;
    }
}
