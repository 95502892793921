import { EnviBrowser } from "../envi-browser/envi-browser";
import { ItemSelect } from "../../common/item-select/item-select";
import { Helpers } from "hiyo/helpers";
import { ServerExportForm } from "../../common/server-export-form/server-export-form";
import { EnviPollutionBrowserOptions } from "./types";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterItem } from "muklit/components/filter/types";
import { RangeInput } from "muklit/components/range-input/range-input";

export class EnviPollutionBrowser extends EnviBrowser<EnviPollutionBrowserOptions> {

    public createTable(): void {
        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/envi-data`,
            http: this.context.invipo.http,
            filter: {
                title: "components.EnviPollutionBrowser.title",
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            form: {
                fieldsets: [
                    {
                        name: "General",
                        fields: [
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new ItemSelect(this.context, {
                                style: "Light",
                                name: "item.id",
                                label: "forms.fields.item",
                                placeholderText: "forms.placeholders.all",
                                distinct: "EnviData",
                                items: []
                            })
                        ]
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            table: {
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                rows: {
                    id: "id"
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true
                    },
                    {
                        name: "item.name",
                        type: "String",
                        property: "item.name",
                        label: "tables.columns.item",
                        width: 320,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "pollution.co",
                        type: "Number",
                        property: "pollution.co",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value)} ppm</div>`
                        },
                        label: "tables.columns.pollutionCo",
                        hidden: !this.context.config.data.EnvironmentalSensor?.pollution?.co,
                        width: 100,
                        align: "Right",
                        sortable: true
                    },
                    {
                        name: "pollution.co2",
                        type: "Number",
                        property: "pollution.co2",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value)} ppm</div>`
                        },
                        label: "tables.columns.pollutionCo2",
                        hidden: !this.context.config.data.EnvironmentalSensor?.pollution?.co2,
                        width: 100,
                        align: "Right",
                        sortable: true
                    },
                    {
                        name: "pollution.no2",
                        type: "Number",
                        property: "pollution.no2",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value)} ppm</div>`
                        },
                        label: "tables.columns.pollutionNo2",
                        hidden: !this.context.config.data.EnvironmentalSensor?.pollution?.no2,
                        width: 100,
                        align: "Right",
                        sortable: true
                    },
                    {
                        name: "pollution.so2",
                        type: "Number",
                        property: "pollution.so2",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value, 1, 1)} ppm</div>`
                        },
                        label: "tables.columns.pollutionSo2",
                        hidden: !this.context.config.data.EnvironmentalSensor?.pollution?.so2,
                        width: 100,
                        align: "Right",
                        sortable: true,
                    },
                    {
                        name: "pollution.pm1",
                        type: "Number",
                        property: "pollution.pm1",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value)} μg/m<sup>3</sup></div>`
                        },
                        label: "tables.columns.pollutionPm1",
                        hidden: !this.context.config.data.EnvironmentalSensor?.pollution?.pm1,
                        width: 100,
                        align: "Right",
                        sortable: true
                    },
                    {
                        name: "pollution.pm25",
                        type: "Number",
                        property: "pollution.pm25",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value)} μg/m<sup>3</sup></div>`
                        },
                        label: "tables.columns.pollutionPm25",
                        hidden: !this.context.config.data.EnvironmentalSensor?.pollution?.pm25,
                        width: 100,
                        align: "Right",
                        sortable: true
                    },
                    {
                        name: "pollution.pm10",
                        type: "Number",
                        property: "pollution.pm10",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value)} μg/m<sup>3</sup></div>`
                        },
                        label: "tables.columns.pollutionPm10",
                        hidden: !this.context.config.data.EnvironmentalSensor?.pollution?.pm10,
                        width: 100,
                        align: "Right",
                        sortable: true
                    },
                    {
                        name: "pollution.o3",
                        type: "Number",
                        property: "pollution.o3",
                        formatter: (value: any, data: any) => {
                            if (value == null) {
                                return;
                            }
                            return `<div class="cell cell-right">${Helpers.toNumber(value)} μg/m<sup>3</sup></div>`
                        },
                        label: "tables.columns.pollutionO3",
                        hidden: !this.context.config.data.EnvironmentalSensor?.pollution?.o3,
                        width: 100,
                        align: "Right",
                        sortable: true
                    },
                    {
                        name: "last",
                        type: "String",
                        property: "last",
                        label: null,
                    }
                ]
            }
        });

        // Close handler
        this.table.onClose = () => {
            // OnClose handler
            this.onClose();
        }

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }

    public openExport(): void {
        // Export form to choose export type
        let form = new ServerExportForm(this.context, {
            style: "Light",
            title: "components.ServerExportForm.title",
            overlay: true,
            closable: true,
            items: [
                {
                    name: "EnviCsv",
                    label: "components.ServerExportForm.types.Csv",
                    checked: true
                }
            ],
            query: this.table.getQuery(),
            total: this.table.pagination.options.total
        });

        // Show form
        form.attach();
    }

}
