import "./energy-consumption-heatmap.scss";
import * as template from "./energy-consumption-heatmap.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { EnergyConsumptionHeatmapOptions } from "./types";
import { Form } from "muklit/components/form/form";
import { ItemSelect } from "../../common/item-select/item-select";
import { Panel } from "../../common/panel/panel";
import { PanelKpis, PanelTable, PanelTableRow } from "../../common/panel/types";
import { Helpers } from "hiyo/helpers";
import { RangeInput } from "muklit/components/range-input/range-input";
import { METRICS } from "../../city/city-subdomain/types";
import { AreaSelect } from "../../common/area-select/area-select";

export class EnergyConsumptionHeatmap extends Panel<EnergyConsumptionHeatmapOptions> {

    // Properties
    public hours: any[];
    public kpis: PanelKpis;
    public table: PanelTable;

    public constructor(context: InvipoContext, options?: EnergyConsumptionHeatmapOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createForm();
    }

    protected createForm(): void {
        // Filter form
        this.form = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "General",
                    fields: [
                        new RangeInput(this.context, {
                            style: "Light",
                            name: "interval",
                            type: "Range",
                            label: "forms.fields.date",
                            value: {
                                from: new Date(new Date().setHours(-24 * 13, 0, 0, 0)).toISOString(),
                                to: new Date(new Date().setHours(24, 0, 0, 0) - 1).toISOString(),
                                range: "Last14Days"
                            },
                            placeholderText: "forms.placeholders.anytime",
                            width: 320,
                            bright: true,
                            required: true
                        }),
                        new AreaSelect(this.context, {
                            style: "Light",
                            name: "areaId",
                            label: "forms.fields.area",
                            value: this.options.itemId,
                            placeholderText: "forms.placeholders.all",
                            areaType: "SmartBuilding",
                            items: [],
                            width: 320,
                            multiselect: true,
                            bright: true
                        })

                    ]
                }
            ]
        });

        // Register component
        this.registerComponent(this.form, "form");
    }

    public async extraLoad(): Promise<void> {
        // Get simplified form data
        let form = this.form.getData(true);

        // Assign form data to panel search options
        this.options.search = this.form.getData();

        // Query string
        //let query = `item.class=${ITEM_CLASS}`;
        let query = "";

        if (form.areaId) {
            query += `&area.id=${form.areaId}`;
        }

        // Interval
        let from = new Date(new Date(form.from));
        let to = new Date(new Date(form.to));

        // Consumption data
        this.hours = await this.context.invipo.getQuery("consumption-by-hour", `${query}&from=${from.toISOString()}&to=${to.toISOString()}`);

        // Calculate highlights
        let averageConsumption = this.hours.map(x => x.consumption).reduce((a, b) => { return a + b }, 0) / this.hours.length;
        let maxConsumption = Math.max(...this.hours.map(x => x.consumption));
        let minConsumption = Math.min(...this.hours.map(x => x.consumption));

        // Consumption KPIs
        this.kpis = {
            label: "components.EnergyConsumptionHeatmap.overview",
            size: "Third",
            data: [
                {
                    label: "components.EnergyConsumptionHeatmap.average",
                    value: this.hours.length ? `${Helpers.toNumber(averageConsumption, 1)} kWh` : "common.unknown",
                    description: this.hours.length ? `${Helpers.toDateString(from)} &ndash; ${Helpers.toDateString(to)}` : null
                },
                {
                    label: "components.EnergyConsumptionHeatmap.highest",
                    value: Number.isFinite(maxConsumption) ? Helpers.toShortDateTimeString(this.hours.find(x => x.consumption == maxConsumption)?.timestamp) : "common.unknown",
                    description: Number.isFinite(maxConsumption) ? `${Helpers.toNumber(maxConsumption, 1)} kWh` : null
                },
                {
                    label: "components.EnergyConsumptionHeatmap.lowest",
                    value: Number.isFinite(minConsumption) ? Helpers.toShortDateTimeString(this.hours.find(x => x.consumption == minConsumption)?.timestamp) : "common.unknown",
                    description: Number.isFinite(minConsumption) ? `${Helpers.toNumber(minConsumption, 1)} kWh` : null
                },
            ]
        }

        // Build count table
        this.table = {
            name: "Table",
            label: "components.EnergyConsumptionHeatmap.table",
            columns: [
                {
                    style: "Label",
                    label: "tables.columns.date",
                    width: "99%"
                },
            ],
            rows: []
        };

        // Add hours as columns
        for (let i = 0; i < 24; i++) {
            this.table.columns.push(
                {
                    style: "Small",
                    label: String(i).padStart(2, "0"),
                    align: "Center",
                    width: "30px"
                }
            )
        }

        // Iterate by hour
        while (from.getTime() < to.getTime()) {
            // Single row
            let row: PanelTableRow = {
                cells: [
                    `${Helpers.toShortWeekDayString(from)} ${Helpers.toDateString(from)}`
                ]
            }

            // Add hour columns
            for (let i = 0; i < 24; i++) {
                // Find data by timestamp
                let data = this.hours.find(x => x.timestamp == from.toISOString());
                let color = "";

                // Get color from range
                if (data) {
                    let range = Helpers.range(0, METRICS.energy.metering.consumption.colors.length - 1, METRICS.energy.metering.consumption.range[0], METRICS.energy.metering.consumption.range[1], data.consumption);
                    color = METRICS.energy.metering.consumption.colors[Math.round(range)];
                }

                // Push consumption to cell
                row.cells.push({
                    label: data ? Helpers.toNumber(data.consumption, 1, 1) : " ",
                    color: `${color}88`
                });

                // Add one more hour
                from.setTime(from.getTime() + 3600000)
            }

            // Add row to table
            this.table.rows.push(row);
        }
    }
}
