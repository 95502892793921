import "./energy-consumption-area-tile.scss";
import * as template from "./energy-consumption-area-tile.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Tile } from "../../common/tile/tile";
import { TileChart, TileKpis } from "../../common/tile/types";
import { Helpers } from "hiyo/helpers";
import { EnergyConsumptionAreaTileOptions } from "./types";
import { METRICS } from "../../city/city-subdomain/types";

export class EnergyConsumptionAreaTile extends Tile<EnergyConsumptionAreaTileOptions> {

    // Properties
    public kpis: TileKpis;
    public chart: TileChart;

    constructor(context: InvipoContext, options?: EnergyConsumptionAreaTileOptions) {
        super(context, template, options);
    }

    public async extraLoad(): Promise<void> {
        // Filter by class
        this.items = this.items.filter(x => x.class == "EnergyMeter");

        // Calculate area consumption
        let average = this.items.map(x => x.data?.consumption?.consumption || 0).reduce((a, b: number) => { return a + b }, 0) / (this.items.length || 1);

        // Build items kpis
        this.kpis = {
            size: "Half",
            kpis: [
                {
                    label: "components.EnergyConsumptionAreaTile.consumption",
                    value: `${Helpers.toNumber(average, 1, 1)} kWh`
                },
                {
                    label: "components.EnergyConsumptionAreaTile.intersections",
                    value: Helpers.toNumber(this.items.length)
                }
            ]
        }

        // Calculate history data (last 2 days)
        this.chart = {
            size: "48",
            data: []
        };

        // Data query
        let from = new Date(new Date().setHours(-24, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        let query = `area.id=${this.options.areaId}&from=${from.toISOString()}&to=${to.toISOString()}`;

        // Load data
        let data = await this.context.invipo.getQuery(`consumption-by-hour`, query);

        // Get history from yesterday's midnight to today's midnight
        for (let h = 0; h < 48; h++) {
            // Find hour in data
            let d = data.find(x => x.timestamp == from.toISOString());

            // Add history line
            if (d) {
                // Add data
                this.chart.data.push({
                    timestamp: from.toISOString(),
                    value: d.consumption,
                    percent: Helpers.range(0, 100, METRICS.energy.metering.consumption.range[0], METRICS.energy.metering.consumption.range[1], d.consumption),
                    tooltip: `${Helpers.toNumber(d.consumption, 1, 1)} kWh`,
                    color: METRICS.energy.metering.consumption.colors[Math.round(Helpers.range(0, METRICS.energy.metering.consumption.colors.length - 1, METRICS.energy.metering.consumption.range[0], METRICS.energy.metering.consumption.range[1], d.consumption))]
                });
            }
            // No data for hour, we are in the future
            else {
                this.chart.data.push({
                    timestamp: from.toISOString(),
                    value: 0
                });
            }

            // Move to next hour
            from.setTime(from.getTime() + 3600000);
        }

        // Create tile status
        if (this.items.find(x => !x.data?.consumption?.consumption == null)) {
            this.status = {
                icon: "Error",
                label: "components.EnergyConsumptionAreaTile.statusError"
            }
        }
    }
}
