import { FeatureCollection } from "geojson";
import { InvipoContext } from "../../context/invipo-context";
import { MapLayer, OCCUPANCY_CIRCLE_AREA, OCCUPANCY_STROKE_AREA, TRANSITION_DURATION } from "muklit/components/basic-map/map-layer";

export class ItemDrawCircleLayer extends MapLayer<InvipoContext> {

    // Properties
    public itemId: string;

    public constructor(context: InvipoContext, itemId?: string) {
        super(context, {
            dynamic: true,
            refreshInterval: 60,
            detail: "CityDetail",
            layer: {
                id: "item-availability-circle",
                type: "circle",
                source: {
                    type: "geojson",
                    data: null
                },
                paint: {
                    //"circle-blur": 0.75,
                    "circle-radius": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6, ["*", ["get", "scale"], 1],
                        12, ["*", ["get", "scale"], 4],
                        16, ["*", ["get", "scale"], 8],
                        19, ["*", ["get", "scale"], 10],
                        22, ["*", ["get", "scale"], 12]
                    ],
                    "circle-color": ["get", "color"],
                    "circle-opacity": 0, // 0 -> 1
                    "circle-opacity-transition": {
                        duration: TRANSITION_DURATION
                    }
                }
            },
            transitions: {
                "circle-opacity": 1,
            }
        });

        // Set itemId
        this.itemId = itemId
    }

    public async load(): Promise<any> {
        // Load items
        let items = this.context.data.getItems();

        // GeoJSON as result
        let json: FeatureCollection = {
            type: "FeatureCollection",
            features: []
        }

        // Push features from items
        for (let item of items) {
            // No location?
            if (!item.geometry?.location) {
                continue;
            }

            json.features.push({
                type: "Feature",
                properties: {
                    tooltip: item.name,
                    detail: this.options.detail,
                    cardId: item.id,
                    itemId: item.id,
                    itemName: item.name,
                    itemClass: item.class,
                    color: (item.id == this.itemId) ? "#A8A8A8" : "#008efa",
                    scale: (item.id == this.itemId) ? 1 : 0.45
                },
                geometry: item.geometry["location"]
            })
        }

        return json;
    }
}
