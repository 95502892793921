import "./user-create-form.scss";
import * as template from "./user-create-form.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Form } from "muklit/components/form/form";
import { TextInput } from "muklit/components/text-input/text-input";
import { UserCreateFormOptions } from "./types";
import { Checkbox } from "muklit/components/checkbox/checkbox";
import { Tabs } from "muklit/components/tabs/tabs";
import { Toggle } from "muklit/components/toggle/toggle";
import { Input } from "muklit/components/input/input";

export class UserCreateForm extends Detail<InvipoContext, UserCreateFormOptions> {

    // Components
    public tabs: Tabs;
    public profile: Form;
    public permissions: Form;

    // Event handling methods
    public onUserCreate(): void {}

    public constructor(context: InvipoContext, options: UserCreateFormOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();
        this.createProfile();
        this.createPermissions();
    }

    private createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Light",
            tabs: [
                {
                    name: "Profile",
                    label: "components.UserDetail.profile",
                    content: "div.content-profile",
                    selected: true
                },
                {
                    name: "Permissions",
                    label: "components.UserDetail.permissions",
                    content: "div.content-permissions"
                }
            ]
        });

        // Register component
        this.registerComponent(this.tabs, "tabs");
    }

    private createProfile(): void {
        // User profile form
        this.profile = new Form(this.context, {
            style: "Light",
            fieldsets: [
                {
                    name: "Left",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "name",
                            label: "forms.fields.name",
                            placeholderText: "forms.placeholders.name",
                            width: 288,
                            required: true,
                            bright: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "title",
                            label: "forms.fields.title",
                            placeholderText: "forms.placeholders.jobTitle",
                            width: 288,
                            bright: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "username",
                            label: "forms.fields.username",
                            placeholderText: "forms.placeholders.username",
                            width: 288,
                            required: true,
                            bright: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "password",
                            label: "forms.fields.password",
                            placeholderText: "forms.placeholders.newPassword",
                            width: 288,
                            password: true,
                            required: true,
                            bright: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "retypePassword",
                            label: "forms.fields.retypePassword",
                            placeholderText: "forms.placeholders.retypePassword",
                            width: 288,
                            password: true,
                            required: true,
                            bright: true
                        })
                    ],
                },
                {
                    name: "Right",
                    fields: [
                        new TextInput(this.context, {
                            style: "Light",
                            name: "email",
                            label: "forms.fields.email",
                            placeholderText: "forms.placeholders.email",
                            width: 288,
                            required: true,
                            bright: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "department",
                            label: "forms.fields.department",
                            placeholderText: "forms.placeholders.department",
                            width: 288,
                            bright: true
                        }),
                        new TextInput(this.context, {
                            style: "Light",
                            name: "phone",
                            label: "forms.fields.phone",
                            placeholderText: "forms.placeholders.phone",
                            width: 288,
                            bright: true
                        }),
                        new Toggle(this.context, {
                            style: "Light",
                            size: "Tall",
                            name: "otp",
                            label: "forms.fields.otp",
                            note: "components.UserCreateForm.otpNote",
                        })
                    ],
                }
            ]
        });

        // Submit form on enter
        this.profile.onSubmit = async () => {
            await this.submit();
        }

        // Register component
        this.registerComponent(this.profile, "profile");
    }

    private createPermissions(): void {
        // User profile form
        let fields: Input[] = [];

        // Generate permission fields from project config
        for (let permission of this.context.options.permissions) {
            fields.push(
                new Checkbox(this.context, {
                    style: "Light",
                    name: permission,
                    label: `permissions.${permission}`
                })
            );
        }

        // Create component
        this.permissions = new Form(this.context, {
            fieldsets: [
                {
                    name: "Right",
                    fields: fields
                }
            ]
        });

        // Register component
        this.registerComponent(this.permissions, "permissions");
    }

    public async submit(): Promise<void> {
        // Basic form validation?
        if (!this.profile.validate()) {
            return;
        }

        // Form data
        let profile = this.profile.getData();
        let permissions = this.permissions.getData();

        // Advanced form validation
        /*if (profile.password?.length < 6) {
            this.profile.getField<TextInput>("password").setInvalid(true, "forms.messages.passwordTooShort");
            return;
        }
        if (profile.password == profile.username) {
            this.profile.getField<TextInput>("password").setInvalid(true, "forms.messages.passwordTooSimple");
            return;
        }
        if (profile.password != profile.retypePassword) {
            this.profile.getField<TextInput>("password").setInvalid(true, "forms.messages.passwordsDontMatch");
            return;
        }
        if (profile.otp == true && !profile.phone?.length) {
            this.profile.getField<TextInput>("phone").setInvalid(true, "forms.messages.phoneRequired");
            return;
        }
        if (profile.otp == true && !profile.phone?.length) {
            this.profile.getField<TextInput>("phone").setInvalid(true, "forms.messages.phoneRequired");
            return;
        }*/

        // User already created?
        /*let user = await this.context.invipo.getDatataset("users", `username=${profile.username}`);
        if (user.data.length > 0) {
            this.profile.getField<TextInput>("username").setInvalid(true, "forms.messages.usernameAlreadyUsed");
            return;
        }*/

        // Show loader
        this.showLoader();

        // Create new user with two form merged together
        try {
            await this.context.invipo.createUser({
                ...profile,
                permissions: Object.keys(permissions)
            });
        }
        catch (e) {
            if (e.status == 422) {
                this.profile.setValidationErrors(e.response);
                return;
            }
        }
        finally {
            this.hideLoader();
        }

        // Hide loader
        this.close();

        // OnProfileSubmit handler
        this.onUserCreate();
    }

}
