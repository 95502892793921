import "./area-detail.scss";
import * as template from "./area-detail.hbs";
import { InvipoArea } from "../../../clients/invipo-client/types";
import { InvipoContext } from "../../../context/invipo-context";
import { Detail } from "muklit/components/detail/detail";
import { Dialog } from "muklit/components/dialog/dialog";
import { AreaDetailOptions } from "./types";
import { AreaForm } from "../area-form/area-form";
import { Tabs } from "muklit/components/tabs/tabs";

export class AreaDetail extends Detail<InvipoContext, AreaDetailOptions> {

    // Properties
    public area: InvipoArea;

    // Components
    public tabs: Tabs;

    // Event handling methods
    public onDetailUpdate(): void {}

    constructor(context: InvipoContext, options: AreaDetailOptions) {
        super(context, template, options);
    }

    public onCreate(): void {
        // Create components
        this.createTabs();

        // Register components that will be automatically attached
        this.registerComponent(this.tabs, "tabs");
    }

    private createTabs(): void {
        // Create component
        this.tabs = new Tabs(this.context, {
            style: "Light",
            tabs: [
                {
                    name: "Detail",
                    label: "components.AreaDetail.general",
                    content: "div.content-general",
                    selected: true
                },
                {
                    name: "Meta",
                    label: "components.AreaDetail.meta",
                    content: "div.content-meta"
                }
            ]
        });
    }

    public openArea(): void {
        // Details form
        let form = new AreaForm(this.context, {
            style: "Light",
            title: null,
            area: this.area,
            overlay: true,
            closable: true
        });

        // Refresh on submit
        form.onSubmit = async () => {
            // Reload data
            await this.load();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show form
        form.attach();
    }

    public async disable(disabled: boolean): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        //await this.context.invipo.disableItem(this.options.itemId, disabled);

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // OnDetailUpdate handler
        this.onDetailUpdate();
    }

    public delete(): void {
        // Dialog to confirm
        let dialog = new Dialog(this.context, {
            style: "Light",
            overlay: true,
            closable: true,
            title: "components.AreaDetail.areaDelete",
            text: this.context.locale.getMessage("components.AreaDetail.reallyDelete", this.area.name),
            labelCancel: "labels.cancel",
            labelConfirm: "labels.delete",
            escalated: true
        })

        dialog.onConfirm = async () => {
            // Delete call
            await this.context.invipo.deleteArea(this.area.id);

            // Close dialog
            dialog.close();

            // Close detail
            this.close();

            // OnDetailUpdate handler
            this.onDetailUpdate();
        }

        // Show dialog
        dialog.attach();
    }

    public async load(): Promise<void> {
        // Show loader
        this.showLoader();

        // Load all data
        this.area = await this.context.invipo.getArea(this.options.areaId);

        // Update panel title
        this.options.title = this.area.name;

        // Component might be gone while loading
        if (!this.isAttached()) {
            return;
        }

        // Hide loader
        this.hideLoader();

        // Redraw with all components
        this.invalidate(true);
    }

}
