import { MapLayer } from "muklit/components/basic-map/map-layer";
import { MuklitComponentOptions } from "muklit/components/muklit-component/types";

// TODO: definice vsech metrik!
export const METRICS: any  = {
    infrastructure: {
        technology: {
            status: {
                range: ["Ok", "Warn", "Error", "Unreachable", "Disconnected"],
                colors: ["#0fd170", "#fcb836", "#ff0038", "#7d00ff", "#999999"]
            },
            availability: {
                range: [0, 100],
                colors: ["#ec383a", "#ff7b43", "#fcb836", "#0fd170"]
            }
        }
    },
    traffic: {
        control: {
            state: {
                range: ["On", "FlashingYellow", "Off", "Dark", "None", "Unknown"],
                colors: ["#298cff", "#be116f", "#f1d200", "#566797", "#161616", "#aaaaaa"]
            },
            mode: {
                range: ["Local", "Manual", "Special", "Central", "None", "Unknown"],
                colors: ["#fcaa36", "#d1c628", "#298cff", "#c128b9", "#161616", "#aaaaaa"]
            },
            occupancy: {
                range: [0, 100],
                colors: ["#0fd170", "#0fd170", "#fcb836", "#ff7b43", "#ec383a"]
            }
        },
        counting: {
            volume: {
                range: [0, 100],
                colors: ["#0fd170", "#0fd170", "#fcb836", "#ff7b43", "#ec383a"]
            }
        },
        travel: {
            delay: {
                range: [0, 60],
                colors: ["#0fd170", "#0fd170", "#fcb836", "#ff7b43", "#ec383a"],
                unit: "min"
            }
        },
        events: {
            events: {
                range: ["Accident", "Closure", "Danger", "Roadworks", "Info"],
                colors: ["#ec383a", "#ffbb00", "#c128b9", "#298cff", "#47cff5"]
            },
        },
        incidents: {
            incidents: {
                range: ["ObstacleOnRoad", "OppositeDriving", "PedestrianOnRoad", "StationaryVehicle", "TrafficJam", "Speeding", "ForbiddenUturn", "ForbiddenLeftTurn", "ForbiddenRightTurn", "ForbiddenParking", "YellowLine", "WhiteLine", "StopLine", "RedLight", "DirectionArrow", "NonMotorizedVehicleOnRoute"],
                colors: ["#ec383a", "#ffbb00", "#c128b9", "#298cff", "#47cff5", "#ec383a", "#ffbb00", "#c128b9", "#298cff", "#47cff5", "#ec383a", "#ffbb00", "#c128b9", "#ec383a", "#ec383a", "#298cff"]
            }
        },
        messages: {
            messages: {
                range: ["RWW", "HLN", "IVS"],
                colors: ["#ffbb00", "#ec383a", "#47cff5"]
            },
            vehicles: {
                range: ["Bus", "SpecialVehicle"],
                colors: ["#298cff", "#ffbb00"]
            }
        },
        signals: {
            lights: {
                range: ["On", "Off", "Unknown"],
                colors: ["#fac800", "#666666", "#aaaaaa"]
            }
        },
        preference: {
            points: {
                range: ["Intersection", "Point"],
                colors: ["#161616", "#fcb836"]
            }
        },
        signs: {
            content: {
                range: ["Active", "None"],
                colors: ["#298cff", "#be116f"]
            }
        },
        violations: {
            count: {
                range: ["Speed", "RedLight", "Weight", "Height", "Parking"],
                colors: ["#ffbb00", "#ec383a", "#c128b9", "#298cff", "#47cff5"]

            }
        },
        waze: {
            density: {
                range: ["SmoothTraffic", "LightTraffic", "ModerateTraffic", "HeavyTraffic", "StandStill"],
                colors: ["#0fd170", "#0fd170", "#fcb836", "#ff0038", "#880038"]
            }
        },
        traveltime: {
            level: {
                range: ["SmoothTraffic", "LightTraffic", "ModerateTraffic", "HeavyTraffic", "StandStill", "Unknown"],
                colors: ["#0fd170", "#0fd170", "#fcb836", "#ff0038", "#880038", "#aaaaaa"]
            }
        }
    },
    transit: {
        public: {
            service: {
                range: ["City", "Intercity"],
                colors: ["#298cff", "#be116f"]
            },
            delay: {
                range: [0, 20],
                colors: ["#298cff", "#fcaa36", "#be116f"],
                unit: "min"
            }
        }
    },
    parking: {
        parking: {
            occupancy: {
                range: ["SpaceAvailable", "AlmostFull", "Full", "Unknown"],
                interval: [[0, 80], [80, 100], [100, 101], [0, 0]],
                colors: ["#0fd170", "#fcaa36", "#ff4d4d", "#aaaaaa"]
            },
            navigation: {
                range: ["ParkingSign"],
                colors: ["#8b88ff"]
            }
        }
    },
    envi: {
        air: {
            temperature: {
                range: [-10, 40],
                colors: ["#9589d3", "#5f8fc5", "#508c3e", "#79921c", "#aba10e", "#dfb106", "#f39606", "#ec5f15", "#be4112", "#8a2b0a"],
                unit: "°C"
            },
            humidity: {
                range: [0, 100],
                colors: ["#e96a07", "#64c078", "#28bad7", "#1b93cf", "#35509d"],
                unit: "%"
            },
            pressure: {
                range: [600, 1200],
                colors: ["#35509d", "#1b93cf", "#28bad7", "#64c078", "#e96a07"],
                unit: "hPa"
            }
        },
        pollution: {
            co: {
                range: [0, 500],
                colors: ["#0fd170", "#0fd170", "#fcb836", "#ff7b43", "#ec383a", "#292929"],
                unit: "ppm"
            },
            no2: {
                range: [0, 50],
                colors: ["#0fd170", "#0fd170", "#fcb836", "#ff7b43", "#ec383a", "#292929"],
                unit: "ppm"
            },
            so2: {
                range: [0, 8],
                colors: ["#0fd170", "#0fd170", "#fcb836", "#ff7b43", "#ec383a", "#292929"],
                unit: "ppm"
            },
            pm1: {
                range: [0, 100],
                colors: ["#0fd170", "#0fd170", "#fcb836", "#ff7b43", "#ec383a", "#292929"],
                unit: "μm/m<sup>3</sup>"
            },
            pm25: {
                range: [0, 100],
                colors: ["#0fd170", "#0fd170", "#fcb836", "#ff7b43", "#ec383a", "#292929"],
                unit: "μm/m<sup>3</sup>"
            },
            pm10: {
                range: [0, 100],
                colors: ["#0fd170", "#0fd170", "#fcb836", "#ff7b43", "#ec383a", "#292929"],
                unit: "μm/m<sup>3</sup>"
            },
            o3: {
                range: [0, 100],
                colors: ["#0fd170", "#0fd170", "#fcb836", "#ff7b43", "#ec383a", "#292929"]
            }
        },
        quality: {
            index: {
                range: [1, 6],
                colors: ["#0fd170", "#0fd170", "#fcb836", "#ff7b43", "#ec383a", "#292929"],
                unit: ""
            }
        },
        noise: {
            level: {
                range: [0, 200],
                colors: ["#0fd170", "#0fd170", "#fcb836", "#ff7b43", "#ec383a", "#292929"],
                unit: "dB"
            }
        }
    },
    energy: {
        battery: {
            voltage: {
                range: [0, 12],
                colors: ["#d3007a", "#d400e1", "#5214ff", "#00cffe", "#00ec87"]
            },
            temperature: {
                range: [0, 100],
                colors: ["#9589d3", "#5f8fc5", "#508c3e", "#79921c", "#aba10e", "#dfb106", "#f39606", "#ec5f15", "#be4112", "#8a2b0a"]
            }
        },
        metering: {
            consumption: {
                range: [0, 100],
                //colors: ["#7cd4ff", "#00acff", "#008cd0", "#ff9046", "#c94f16"]
                //colors: ["#7CD4FF", "#99A5D2", "#C55E8E", "#D34777", "#F0184A"],
                //colors: ["#7CD4FF", "#85C5E5", "#8DB6CB", "#96A8B1", "#9E9997", "#A78A7E", "#AF7B64", "#B86D4A", "#C05E30", "#C94F16"]
                colors: ["#0fd170", "#5EC95D", "#ADC049", "#fcb836", "#FDA43A", "#FE8F3F", "#ff7b43", "#F96540", "#F24E3D", "#ec383a"]
            }
        },
        lights: {
            state: {
                range: ["On", "Off", "Unknown"],
                colors: ["#fac800", "#666666", "#aaaaaa"]
            },
            topology: {
                range: ["Master", "Slave"],
                colors: ["#c800fa", "#00c8c8"]
            }
        }
    },
    safety: {
        access: {
            authorization: {
                range: [0, 100],
                colors: ["#0fd170", "#fcb836", "#ff7b43", "#ec383a"]
            }
        },
        cameras: {
            surveillance: {
                range: ["Active", "None"],
                colors: ["#298cff", "#be116f"]
            }
        }
    },
    waste: {
        waste: {
            collection: {
                range: [0, 28],
                colors: ["#0fd170", "#0fd170", "#fcb836", "#ff7b43", "#ec383a"]
            }
        },
        garbage: {
            level: {
                range: [0, 100],
                colors: ["#7cd4ff", "#00acff", "#008cd0", "#ff9046", "#c94f16"],
                unit: "%"
            },
            temperature: {
                range: [0, 100],
                colors: ["#9589d3", "#5f8fc5", "#508c3e", "#79921c", "#aba10e", "#dfb106", "#f39606", "#ec5f15", "#be4112", "#8a2b0a"],
                unit: "°C"
            }
        }
    },
    services: {
        hotspots: {
            connections: {
                range: [0, 200],
                colors: ["#7cd4ff", "#00acff", "#008cd0", "#ff9046", "#c94f16"]
            }
        }
    }
}

export interface SubdomainMetrics {
    name: string;
    itemClass?: string;
    itemFeatures?: string;
    areaType?: string;
    layers: MapLayer[];
    selected?: boolean;
}

export interface LegendRange {
    range: number[];
    colors: string[];
    count?: number;
    value?: number;
    percent?: number;
    unit?: string;
    label?: string;
}

export interface LegendSymbol {
    type: "Circle" | "Line";
    symbol: string;
    metrics?: string;
    count?: number;
    label?: string;
    total?: number;
    percent?: number;
    color?: string;
    selectable?: boolean;
}

export interface SubdomainLegend {
    description?: string;
    range?: LegendRange;
    symbols?: LegendSymbol[];
}

export interface CitySubdomainOptions extends MuklitComponentOptions {
    domain?: string;
    //itemClass?: string;
    //areaType?: string;
    metrics?: SubdomainMetrics[];
    selected?: boolean;
}
